<h1>Problem erkannt</h1>
<div>
  <p>
    Die <b>Systemzeit</b> weicht stark ab.
  </p>
  <p>
    Bitte korrigieren Sie die Zeit ihres Betriebssystems.
  </p>
  <p>
    Sollte diese Meldung danach nicht verschwinden, wenden Sie sich bitte an den Benutzerservice.
  </p>
</div>

<div>
  <button (click)="close()">Schließen</button>
</div>
